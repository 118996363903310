.homepage-layers-item {
   width: 100%;
   max-width: $desktop;
   margin: 0 auto;
   text-align: center;
   display: block;
   &.full-width {
      max-width: 100%;
   }
   &.padding-top {
      @extend %block-padding-top;
   }
   &.padding-bottom {
      @extend %block-padding-bottom;
   }
   & h1 {
      position: relative;
      display: block;
      margin-bottom: 1.25rem;
      z-index: 1;
      text-align: center;
      &.headline-black {
         color: $black;
      }
      &.headline-white {
         color: $white;
      }
      &.headline-dark-gray {
         color: $darkgray;
      }
      &.headline-red {
         color: $red;
      }
      &.headline-blue {
         color: $blue;
      }
      
      @include tablet-wide {
         text-align: left;
         display: inline-block;
      }
   }
   & .description {
      display: block;
      font-size: 1.0rem;
      text-align: center;
      margin-bottom: 1.5rem;
      @include tablet-wide {
         font-size: 1.2rem;
         text-align: center;
      }
   }
   &.dark-gray .description { color: white; }
   &.black .description { color: white; }
   &.red .description { color: white; }
   &.blue .description { color: white; }

   &-text {
      display: block;
      width: 100%;
      max-width: $desktop;
      margin: 0 auto;
      padding: 0 2rem;
      &.contents_full_width {
         max-width: 100%;
         @include md {
            max-width: $desktop-medium;
         }
      }
   }

   &-grid {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      align-content: flex-start;
      width: 100%;
      max-width: $desktop;
      margin: 0 auto;
      padding: 0 2rem;
      &.contents_full_width {
         max-width: 100%;
         @include md {
            max-width: $desktop-medium;
      	}
      }
   }

}
