.home-featured  {
   &-item {
      background-color: $darkgray;
      width: 100%;
      max-width: 100%; 
      height: auto;
      max-height: auto;
      position: relative;
      margin: 0.5rem auto;
      transition: $slow;
      opacity: 0;
      transform: translateY(4rem);
      &.reveal {
        opacity: 1;
         transform: translateY(0);
      }
      &:nth-child(2) {
         transition-delay: .5s;
      }
      &:nth-child(3) {
         transition-delay: 1.0s;
      }
      @include tablet {
         width: 32%;
         height: auto;
         margin: 0 auto;
      }
      @include md {
         width: 100%;
         max-height: 400px;
         max-width: 480px;
         height: 400px;
      }
      &:hover {
         -webkit-box-shadow: 0 0 20px 0 rgba(0,0,0,.3), inset 0 0 0 15px $white;
         box-shadow: 0 0 20px 0 rgba(0,0,0,.3), inset 0 0 0 15px $white;
         transition-delay: 0s;
      }
      &.gray .home-featured-text-description { color: $black !important; }
      &.white .home-featured-text-description { color: $black !important; }
      &.clear .home-featured-text-description { color: $black !important; }
      &.light-gray .home-featured-text-description { color: $black !important; }
   }
   &-aspect {
      position: relative;
      width:100%;
      height: 100%; 
      display: block;
      text-decoration: none !important;
      overflow: hidden;
      &:hover .home-featured-photo {
         width: calc(100% - 30px);
         height: calc(100% - 30px);
         margin: 15px;
         transition-delay: 0s;
      }
      &:hover .home-featured-photo-img {
         -moz-transform: scale(1.25);
         -webkit-transform: scale(1.25);
         -o-transform: scale(1.25);
         -ms-transform: scale(1.25);
         transform: scale(1.25);
         transition-delay: 0;
      }
      &:hover .home-featured-text {
      }
   }
   &-text {
      position: relative;
      margin: 0;
      width: 100%;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
      z-index: 200;
      padding: 2rem;
      text-align: center;
      transition: $slow;
      &-headline {
         position: relative;
         display: inline-block;
         font-family: $headline2 !important;
         @extend %headline3;
         letter-spacing: -.5px;
         margin-bottom: 1.25rem;
         transition: $slow;
      }
      &-description {
         display: block;
         color: $white !important;
         font-size: 1.2rem;
         line-height: 1.4;
         margin-bottom: 1rem;
      }
   }
   &-photo {
      z-index: 100;
      position: absolute;
      width: 100%;
      height: 100%;
      background: $clear;
      overflow: hidden;
      transition: $slow;
      &-img {
         width: 100%;
         height: 100%;
         background-size: cover;
         background-repeat: no-repeat;
         background-blend-mode: luminosity;
         opacity: .25;
         transition: $veryslow;
      }
   }
}


.home-text-only  {
   &-item {
      position: relative;
      padding: 1rem;
      text-align: center;
      margin: 0 auto;
      width: 100%;
      transition: $slow;
      opacity: 0;
      transform: translateY(4rem);
      &.reveal {
        opacity: 1;
         transform: translateY(0);
      }
      &:nth-child(2) {
         transition-delay: .5s;
      }
      &:nth-child(3) {
         transition-delay: 1.0s;
      }
      &-center {
      }
      &-col {
      }
      &-headline {
         @extend %headline5;
         color: $darkgray;
         transition: $slow;
      }
      &-text {
         display: block;
         color: $darkgray;
      }
      & .button.more {
         color: $darkgray;
         &:after {
            background: $darkgray;
            z-index: 1;
         }
      }
      &::after {
         @include tablet-wide {
            content: "";
            width: 1px;
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
            background: $gray; 
         }
      }
      &:last-child::after {
         display: none;
      }
      &:hover {
         background: $lightgray;
         transition-delay: 0s;
         & .home-text-only-item-headline {
            color: $red;
         }
      }
      @include tablet-wide {
         width: 20%;
         padding: 1.5rem;
      }
   }
}


.home-text-photo  {
   &-item {
      width: 100%;
      text-align: left;
      transition: $slow;
      opacity: 0;
      transform: translateY(4rem);
      &.reveal {
        opacity: 1;
         transform: translateY(0);
      }
      &:nth-child(2) {
         transition-delay: .5s;
      }
      &:nth-child(3) {
         transition-delay: 1.0s;
      }
      &:hover {
         -moz-transform: scale(1.1);
         -webkit-transform: scale(1.1);
         -o-transform: scale(1.1);
         -ms-transform: scale(1.1);
         transform: scale(1.1);
         transition-delay: 0s;
      }
      &-center {
         width: 100%;
         height:  100%;
         display: flex;
         flex-wrap: nowrap;
         justify-content: space-between;
         align-content: flex-start;
      }
      &-col {
         &:first-child {
            width: 40%;
         }
         &:last-child {
            margin: 1.5rem;
            width: 60%;
         }
      }
      &-pic {
         width: 100%;
         height: 100%;
         background-size: cover;
         background-position: center center;
         display: block;      
      }
      &-headline {
         @extend %headline4;
      }
      &-text {
         display: block;
         color: $darkgray;
         margin-bottom: .5rem;
      }
      & .button.more {
         color: $yellow;
         left: -.25rem;
         &:after {
            z-index: 1;
         }
      }
      &:first-child {
         margin-bottom: 1rem;
         @include tablet-wide {
            margin-bottom: 0;
         }
      }
      @include tablet {
         width: 60%;
      }
      @include tablet-wide {
         width: 45%;
      }
   }

}


.home-callout {
   &-item {
      width: 100%;
      margin-bottom: 1rem;
      opacity: 0;
      transform: translateY(4rem);
      transition: $slow;
      &.reveal {
        opacity: 1;
         transform: translateY(0);
      }
      &:nth-child(2) {
         transition-delay: .5s;
      }
      &:nth-child(3) {
         transition-delay: 1.0s;
      }
      &:nth-child(4) {
         transition-delay: 1.5s;
      }
      &:nth-child(5) {
         transition-delay: 2.0s;
      }
      &-text {
         text-align: center;
         &-lg {
            display: block;
            @extend %headlineMega;
            font-weight: 900;
            margin-bottom: 0.25rem; 
            letter-spacing: 1px;
            color: $red;
            @include tablet-wide {
               margin-bottom: -.25rem;
            }
         }
         &-sm {
            font-family: $headline2 !important;
            @extend %headline4;
            color: $white;
            margin-bottom: 1rem;
         }
      }
      @include tablet-wide {
         width: 30%;
         margin-bottom: 0;
      }
   }
}

.home-graphic-wrapper {
   position: relative;
   display: block;
}

.logo-slider {
   display: block;
   overflow: hidden;
   padding: 0;
   margin: 0 auto;
   width: 100%;
   @include tablet {
   }
   @include tablet-wide {
   }
   @include desktop {
   }
   @include md {
   }
   
   & .slick-dots {
     list-style: none;
     width: 100%;
     margin: 0 auto;
     text-align: center;
     & li {
       display: inline-block;
       margin: .25rem;
       width: 30px;
       height: 30px;
       background: $lightgray;
       border-radius: 100%;
       & button {
         opacity: 0;
       }
       &.slick-active {
           background: $blue;
       }
       &:hover {
           background: $red;
       }
     }
  }
}

.logo-item {
  &.slide {
    background-color: $white;
    @include size(50%, 150px);	
    position: relative;
    margin: 0 auto;
    transition: $slow;
    overflow: hidden;
    padding: .5rem;
    @include tablet {
     padding: 1rem;
     height: 200px;
    }
    @include tablet-wide {
     height: 150px;
    }
    @include desktop {
     height: 200px;
    }
    @include md {
     height: 200px;
    }
  }
  
  &-figure {
    z-index: 1;
    position: relative;
    overflow: hidden;
    transition: $slow;
    position: relative;
    width: 100%;
    height: 100%;
    display: block;   
    & img {
      width: 100%;
      height: auto;
      text-align: center;
      position: relative;
      margin: 0;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
    }
  }
}   


.home-graphic2 {
   &-item {
      transition: $slow;
      width: 80%;
      margin-bottom: 1rem;
      opacity: 0;
      transform: translateY(4rem);
      &.reveal {
        opacity: 1;
         transform: translateY(0);
      }
      &:nth-child(2) {
         transition-delay: .5s;
      }
      &:nth-child(3) {
         transition-delay: 1.0s;
      }
      &:nth-child(4) {
         transition-delay: 1.5s;
      }
      &:nth-child(5) {
         transition-delay: 2.0s;
      }
      &-figure {
         z-index: 1;
         position: relative;
         overflow: hidden;
         transition: $slow;
         position: relative;
         width: 100%;
         height: 100%;
         display: block;
         &-img {
            width: 100%;
            height: auto;
            display: block;
            text-align: center;
            position: relative;
            margin: 0;
            top: 50%;
            left: 50%;
            margin-right: -50%;
            transform: translate(-50%, -50%);
         }           
      }
      &:hover {
         -moz-transform: scale(1.1);
         -webkit-transform: scale(1.1);
         -o-transform: scale(1.1);
         -ms-transform: scale(1.1);
         transform: scale(1.1);
         transition-delay: 0;
      }
      @include tablet {
      }
      @include tablet-wide {
         margin-bottom: 0;
      }

   }
}

.join-form {
  position: relative;
  padding: 1.25rem 1.25rem 3rem;
  display: block;
  width: 100%;
  flex-direction: row;
  align-items: end;
  &.unsubscribe {
    max-width: $tablet;
    margin: 0 auto;
  }
  @include phone {
    width: calc(100% + 2rem);
    position: relative;
  }   
  @include tablet {
    padding: 2rem 2rem 3rem;
    display: block;
  }  
  @include desktop {
    padding: 3rem;
    display: flex;
  }
  @include md {
    padding: 3.5rem;
  }  
  &-headline {
    @extend %headline4;
    color: $white;
    text-transform: none;
    line-height: 1.2;
    margin: 0 auto;
    display: block;
    text-align: center;
    @include tablet {
      margin-right: 1rem;
      display: inline-block;
      text-align: left;
      line-height: 1.75;
    }  
  }
  &-txt {
    @extend %basic-text;
    color: $white;
    line-height: 1.3;
    margin-bottom: 1rem;
    @include desktop {
      margin-bottom: 1.5rem;
    }
  } 
  &-row {
    @include tablet {
      display: grid;
      grid-gap: 0.25rem;
      padding: 0 0.25rem;
      grid-template-columns: 2fr 2fr 3fr 1fr;
      max-width: 900px;
      margin: 0 auto;
    }
  }
  &-item {
    padding: 0;
    position: relative;
    width: 100%;
    &.wide {
     width: 100%;
    }
  }
  &-label {
    font-size: .95rem;
    padding: .25rem 0 .15rem;
    color: $white;
    display: block;
    cursor: pointer;
    text-align: left;
  }
  &-input {
    width: 100%;
    border: 2px solid $white;
    background: rgba($white, .5);
    padding: 1rem;
    font-family: $body;
    color: $black;
    border-radius: 5px !important;
    outline: 0;
    cursor: pointer;
    transition: .5s all $slow-curve;
    &:hover {
      background: $white;
    }
    &:focus {
      background: $white;
    }
  }
  &-button {
    &-row {
      margin-top: .5rem;
      @include desktop {
        margin-top: 0;
      }
    }
    background: transparent;
    position: relative;
    outline: 0;
    border: 0;
    cursor: pointer;
    &-txt {
      color: $blue;
      font-weight: 900;
      text-transform: uppercase;
      font-family: $button;
      font-size: 1.0rem;
      line-height: 1;
      padding: 1.1rem 2rem 1.1rem;
      display: block;
      position: relative;
      z-index: 10;
      white-space: nowrap;
      transition: color .1s $slow-curve;
      transition-delay: .3s;
    }
    &-bg {
      @include size(100%);
      background: $white;
      @include absolute(left 0 top 0);
      z-index: 1;
      display: block;
      overflow: hidden;
      transition: background .2s $slow-curve;
      transition-delay: .4s;
      border-radius: 50px;
      &:before, &:after {
        @include absolute(left 0 top 0);
        content:"";
        display: block;
        @include size(0, 100%);
        transition: .5s width $slow-curve;
      }   
      &:before {
        background: $red;
        z-index: 1;
      }
      &:after {
        background: $white;
        transition-delay: .15s;
        z-index: 10;
      }   
    }
    &:hover {
      .join-form-button {
        &-txt {
          color: $red;
        }
        &-bg {
          background: $blue;
          &:before,
          &:after {
            width: 100%;
          }
          &:after {
          }     
        }
      }
    }
  }
  &-response {
    position: relative;
    background: none;
    padding: 1.25rem 1.25rem 3rem;
    /*border: 3px solid $blue;*/
    display: block;
    @include phone {
      width: calc(100% + 2rem);
      position: relative;
      left: -1rem;
    }   
    @include tablet {
      padding: 2rem 2rem 3rem;
    }  
    @include desktop {
      padding: 3rem;
    }
    @include md {
      padding: 3.5rem;
    }   
    &-headline {
      @extend %headline3;
      line-height: 1.2;
      margin-bottom: 1rem;
      @include tablet {
        margin-bottom: 1rem;
      }  
    }
    &-txt {
      @extend %basic-text;
      line-height: 1.3;
      margin-bottom: 1rem;
      @include desktop {
        margin-bottom: 1.5rem;
      }
    } 
  }
  strong.error {
    @include absolute(right .25rem top .47rem);
    background: $red;
    color: $white;
    padding: .25rem .25rem .1rem;
    font-size: .7rem;
    text-transform: uppercase;
    display: inline-block;
    font-weight: 400;
    border: 2px solid $red;
    text-transorm: uppercase;
  }
}
